<template>
  <div>
    <el-table
      @selection-change="handleSelectionChange"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      :data="tableData"
    >
      <el-table-column type="selection" width="35" v-if="isSelect">
      </el-table-column>

      <el-table-column
        v-for="item in headerList"
        :key="item.value"
        :label="item.key"
        :align="item.hasOwnProperty('align') ? item.align : 'left'"
        :fixed="item.hasOwnProperty('fixed') ? 'right' : false"
        :width="item.hasOwnProperty('width') ? item.width : ''"
        :min-width="item.hasOwnProperty('minWidth') ? item.minWidth : ''"
      >
        <template slot-scope="scope">
          <div v-if="item.type">
            <span v-if="item.type === 'dictionary'">{{
              scope.row[item.value] | getText(item.dictionary)
            }}</span>
            <span v-else-if="item.type === 'size'">{{
              scope.row[item.value] | FileSize
            }}</span>
            <span v-else-if="item.type === 'unit'">{{
              scope.row[item.value] + item.unit
            }}</span>
            <span v-else-if="item.type === 'time'">
              {{ scope.row[item.value] | changDataTime }}
            </span>
            <span v-else-if="item.type === 'sfmTime'">{{
              scope.row[item.value] | getSFM
            }}</span>
            <div v-else-if="item.type === 'tags'">
              <el-tag
                type="success"
                v-for="obj in JSON.parse(scope.row[item.value])"
                :key="obj.t"
                style="margin-right: 10px"
                >{{ obj.tag }}</el-tag
              >
            </div>
            <template v-else-if="item.type === 'image'">
              <template v-if="scope.row[item.value].indexOf(',') != -1">
                <span
                  v-for="(img, imgIndex) in setDatas(scope.row[item.value])"
                  :key="imgIndex"
                  :title="setImages(img)"
                >
                  <el-image
                    v-if="img"
                    :src="setImages(img)"
                    style="width: 50px; height: 28px"
                    lazy
                    :preview-src-list="setpreview(item.value)"
                  >
                    <div slot="error" class="image-slot">
                      <img
                        :src="setImages(img)"
                        alt=""
                        style="width: 50px; height: 28px"
                      />
                    </div>
                  </el-image>
                </span>
              </template>
              <template v-else>
                <span :title="setImages(scope.row[item.value])">
                  <el-image
                    v-if="scope.row[item.value]"
                    :src="setImages(scope.row[item.value])"
                    style="width: 50px; height: 28px"
                    lazy
                    :preview-src-list="setpreview(item.value)"
                  >
                    <div slot="error" class="image-slot">
                      <img
                        :src="setImages(scope.row[item.value])"
                        alt=""
                        style="width: 50px; height: 28px"
                      />
                    </div>
                  </el-image>
                </span>
              </template>
            </template>
            <span v-else-if="item.type === 'Boolean'">{{
              scope.row[item.value] ? "是" : "否"
            }}</span>
            <div v-else-if="item.type === 'handle'">
              <span
                v-for="(obj, indexs) in JSON.parse(scope.row[item.value])"
                :key="obj.t"
                >{{
                  indexs === JSON.parse(scope.row[item.value]).length - 1
                    ? obj.tag
                    : obj.tag + ">"
                }}</span
              >
            </div>
            <span v-else-if="item.type === 'src'">
              <el-input
                size="small"
                type="text"
                readonly
                v-model="scope.row[item.value]"
              ></el-input>
            </span>
            <span v-else-if="item.type === 'filter'">{{
              scope.row[item.value] | changeSets(parentsList)
            }}</span>
            <span v-else-if="item.type === 'obj'">{{
              scope.row[item.value] | changeObjData(item.objValue)
            }}</span>
          </div>
          <div v-else>
            <span>{{ scope.row[item.value] }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="富文本" fixed="right" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            @click="$emit('editRichText', scope.row)"
          ></el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="150px" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-video-play"
            v-if="isLook && scope.row.fs_resource_status === 'Completed'"
            @click="$emit('lookVideo', scope.row)"
          ></el-button>
          <el-button
            v-if="!userInfo.is_outer"
            type="text"
            size="small"
            v-has="editUrl"
            @click="$emit('editData', scope.row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="
              isLook &&
              delFIleList.includes(scope.row.fs_resource_status) &&
              !userInfo.is_outer
            "
            v-has="deleteUrl"
            type="text"
            size="small"
            @click="deleteFile(scope.row.id)"
          >
            删除
          </el-button>
          <el-button
            v-if="!isLook && !userInfo.is_outer"
            v-has="deleteUrl"
            type="text"
            size="small"
            @click="deleteFile(scope.row.id)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            size="small"
            v-if="
              isLook &&
              scope.row.fs_resource_status !== 'Completed' &&
              !userInfo.is_outer
            "
            v-has="checkUrl"
            @click="checkData(scope.row)"
            title="刷新状态"
          >
            检查状态
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page.sync="page"
      @size-change="$parent.changeSize"
      @current-change="$parent.getVideoList"
      :total="total"
    >
    </el-pagination>
    <!-- <el-pagination
          :page-sizes="[10, 25, 50, 100]"
          :current-page="params.page"
          :page-size="params.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          v-if="total > 10"
          @size-change="changeSize"
          @current-change="changeCurrent"
        ></el-pagination> -->
  </div>
</template>

<script>
import API from "@/api";
import local from "@/utils/localStroage.js";

export default {
  name: "videoTable",
  props: [
    "headerList",
    "tableData",
    "delFIle",
    "total",
    "pages",
    "pageSize",
    "isLook",
    "axiosTile",
    "parentsList",
    "editUrl",
    "checkUrl",
    "deleteUrl",
    "isSelect",
  ],
  data() {
    return {
      loading: false,
      multipleSelection: [],
      delFIleList: [
        "UploadError",
        "FileMergeError",
        "CompletedFail",
        "FileConvertError",
      ],
      page: 0,
      userInfo: undefined,
    };
  },
  filters: {
    getText(value, ArrData) {
      let flag = ArrData.find((v) => v.key === value);
      return flag && flag.value;
    },
    changeSets(val, ArrData) {
      const newArr = ArrData.filter((item) => item.id == val);
      return newArr.length ? newArr[0].title : "";
    },
    changeObjData(val, key) {
      return val ? val[key] : "";
    },
  },
  watch: {
    pages(pages) {
      this.page = pages;
    },
  },
  created() {
    this.userInfo = local.get("userInfo");
  },
  methods: {
    deleteFile(id) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        API.delVideoFile(this.delFIle, { id: id }).then((res) => {
          if (!res.code) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.loading = true;
            this.$parent.getVideoList(this.page);
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    handleSelectionChange(val) {
      // this.$emit('batchlaunch',val)
      this.multipleSelection = val;
    },
    checkData(data) {
      API.getVideocheck({ id: data.id }, this.axiosTile).then((res) => {
        if (!res.code) {
          this.$message({
            type: "success",
            message: "检查状态成功!",
          });
          this.loading = true;
          this.$parent.getVideoList(this.page);
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    setImages(val) {
      // 判断是否拥有域名 如果有 去掉域名 字符串第一位是'/'
      if (val.indexOf("http") === 0) {
        val = "/" + val.split("/").slice(3).join("/");
      } else if (val.indexOf("/") !== 0) {
        val = "/" + val;
      }
      // 根据去掉域名后的数据进行匹配
      let data = JSON.parse(JSON.parse(localStorage.getItem("imageUrl")));
      let strValue = "";
      for (const key in data) {
        if (key.indexOf("/") !== 0 && key !== "*") {
          //如果匹配键名前面不带'/'
          if (val.indexOf("/" + key + "/") === 0) {
            //
            strValue = data[key];
          }
        } else if (key.indexOf("/") === 0 && key !== "*") {
          //带'/'
          if (val.indexOf(key + "/") === 0) {
            //有匹配的
            strValue = data[key];
          }
        }
      }
      if (strValue) {
        return strValue + val;
      } else {
        return data["*"] + val;
      }
    },
    setpreview(val) {
      // 处理后的数据
      let list = [];
      this.tableData.forEach((v) => {
        if (v[val].indexOf(",") != -1) {
          let arr = v[val].split(",");
          arr.forEach((a) => {
            if (a.indexOf("http") === 0) {
              list.push("/" + a.split("/").slice(3).join("/"));
            } else if (val.indexOf("/") !== 0) {
              list.push("/" + a);
            } else {
              list.push(a);
            }
          });
        } else {
          if (v[val].indexOf("http") === 0) {
            list.push("/" + v[val].split("/").slice(3).join("/"));
          } else if (val.indexOf("/") !== 0) {
            list.push("/" + v[val]);
          } else {
            list.push(v[val]);
          }
        }
      });
      let imgList = [];
      let data = JSON.parse(JSON.parse(localStorage.getItem("imageUrl")));
      list.forEach((item) => {
        let strVal = "";
        for (const key in data) {
          if (key.indexOf("/") !== 0 && key !== "*") {
            //如果匹配键名前面不带'/'
            if (item.indexOf("/" + key + "/") === 0) {
              //
              strVal = data[key];
            }
          } else if (key.indexOf("/") === 0 && key !== "*") {
            //带'/'
            if (item.indexOf(key + "/") === 0) {
              //有匹配的
              strVal = data[key];
            }
          }
        }
        if (strVal) {
          imgList.push(strVal + item);
        } else {
          imgList.push(data["*"] + item);
        }
      });
      return imgList;
    },
    setDatas(data) {
      let datas = data.split(",");
      return datas;
    },
    // 编辑
    // editData(){}
  },
};
</script>

<style scoped></style>
