import { render, staticRenderFns } from "./videoDialog.vue?vue&type=template&id=81975db6&scoped=true&"
import script from "./videoDialog.vue?vue&type=script&lang=js&"
export * from "./videoDialog.vue?vue&type=script&lang=js&"
import style0 from "./videoDialog.vue?vue&type=style&index=0&id=81975db6&prod&lang=css&"
import style1 from "./videoDialog.vue?vue&type=style&index=1&id=81975db6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81975db6",
  null
  
)

export default component.exports